import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import {controls as authDataParams} from './authDataParams';

import { userConnect, newCredentials, userLastLogIn, checkAuthTimeOut } from '../../features/credentials/credentialsSlice';
import {  setFormInError, selectFormInError, selectlibelleError  } from '../../features/loading/loadingSlice';

import classes from './LogIn.module.css';

function LogIn(props) {
    const [isFormValid, setisFormValid] = useState(false);
    const [signInForm, setsignInForm] = useState(authDataParams);

    const formInError = useSelector(selectFormInError);
    const libelleError = useSelector(selectlibelleError);
    const dispatch = useDispatch();

    const formSubmit = async (event) => {
        event.preventDefault();

        const email = signInForm.email.value;
        const password = signInForm.password.value;

        const authCredentials ={
            email: email,
        };

        dispatch(userConnect(authCredentials))
            .then ((res) => {
                if (res.payload?.success) {
                    if (bcrypt.compareSync( password + email, res.payload.user.password)){

                        dispatch(newCredentials(res.payload.token,res.payload.tokenExpireTime,res.payload.refreshtoken,res.payload.refreshTokenExpireTime,res.payload.user.id, res.payload.user.email,res.payload.user.lastlogin));

                        dispatch(userLastLogIn(authCredentials))
                            .catch((err) => {
                                console.log(err)
                        });

                        dispatch(checkAuthTimeOut(res.payload.refreshTokenExpireTime));


                        props.logInButtonClick();
                    } else {
                        dispatch(setFormInError("Email/Password not found"));
                    }
                }
            } );
    }

    const inputChangeHandler = (event, element) => {
        const updatedSignInForm={...signInForm};
        const updatedSignInFormElement={...updatedSignInForm[element]};

        updatedSignInFormElement.value=event.target.value;
        updatedSignInFormElement.touched=true;
        updatedSignInFormElement.valid= checkValidity(updatedSignInFormElement.value,updatedSignInFormElement.validation);

        updatedSignInForm[element] = updatedSignInFormElement;

        let formIsValid = true;
        for (const field in updatedSignInForm ) {
                formIsValid = updatedSignInForm[field].valid && formIsValid;
        }

        setsignInForm(updatedSignInForm);
        setisFormValid(formIsValid);
    }

    const checkValidity = (value, rules) => {

        if (!rules) {
            return true;
        }

        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    const formElementArray = [];

    for (const key in signInForm) {
        if (Object.hasOwnProperty.call(signInForm, key)) {
            formElementArray.push({
                id:key,
                config: signInForm[key]
            })
        }
    }

    let form=(
        <div className= {classes.Form}>
            <div className={classes.Title}>
                <h1>Log In</h1>
                <img src='login.png'
                        alt="Log In" height="30px"></img>
            </div>

            {formInError &&
                <label className={classes.Error}>{libelleError}</label>
            }

            <form onSubmit= {formSubmit}>
            {formElementArray.map( element =>  (
                <Input
                        key={element.id}
                        elementType={element.config.elementType}
                        elementConfig={element.config.elementConfig}
                        value={element.config.value}
                        invalid={!element.config.valid}
                        checkValidation={element.config.validation}
                        touched={element.config.touched}
                        changed={(event) => inputChangeHandler(event, element.id)}
                />
            ))}
                <div className={classes.LogInButtonDiv}>
                    <Button btnType="Outline-Success"
                            disabled={!isFormValid}>
                            Log In
                    </Button>
                </div>
            </form>
        </div>
    )

    return (
        <div className={classes.login}>
            {form}
            <div className="Button">
                <Button btnType="Normal Info" clicked={props.homeClick}> Home </Button>
            </div>

        </div>
    );
}

export default LogIn;
